import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  _url = environment.baseUrl;
  constructor(private _http: HttpClient) { }

  login(email, password){
    return this._http.post<any>(this._url+'login', {"email":email, "password" : password},{
      headers:{"content-type":"application/json"}
    })
  }
}
