import { Component, OnInit, NgModule } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { RouterModule, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Title } from "@angular/platform-browser";
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  toastConfig: any = {
    timeOut: 3000,
    progressBar: true,
    progressAnimation: 'decreasing',
    positionClass: 'toast-bottom-right'
  }
  _cryptoKey = environment.cryptoKey;


  constructor(private formBuilder: FormBuilder, private _loginService: LoginService, private router: Router, private titleService: Title, private toastr: ToastrService) {
    this.titleService.setTitle("Login : CustoEngage");
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    // display form values on success
    let email = this.registerForm.controls.email.value;
    let password = this.registerForm.controls.password.value;

    this._loginService.login(email, password)
      .subscribe(
        data => {
          if (data.statusCode == 100) {
            if (window.localStorage) {
              // Encrypt
              let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data.data[0]), this._cryptoKey);
              let token = CryptoJS.AES.encrypt(JSON.stringify(data.token), this._cryptoKey);
              localStorage.setItem('userDetails', ciphertext);
              localStorage.setItem('token', token);
              this.router.navigate(['/dashboard']);
            } else {
              console.log('Upgrade your browser')
            }
            this.toastr.success(data.message, '', this.toastConfig)
          } else {
            this.toastr.error(data.message, '', this.toastConfig)
          }
        }
      )

  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }


}