import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DefaultLayoutService {

  _url = environment.baseUrl;

  constructor(private _http: HttpClient) { }

  authVerify() {

    let token = localStorage.getItem('token');
    var bytes = CryptoJS.AES.decrypt(token.toString(), environment.cryptoKey);
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    let header = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': decryptedToken
    })

    return this._http.post<any>(this._url + 'verifyToken',
      {}, {
      headers: header
    })

  }
}
