import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'masters',
        loadChildren: () => import('./views/masters/masters.module').then(m => m.MasterModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportModule)
      },    
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'product-suggestion-master',
        loadChildren: () => import('./views/product-suggestion-master/product-suggestion-master.module').then(m => m.ProductSuggestionMasterModule)
      },
      {
        path: 'qr-manager',
        loadChildren: () => import('./views/qr-manager/qr-manager.module').then(m => m.QRManagerModule)
      },
      {
        path: 'seo',
        loadChildren: () => import('./views/seo/seo.module').then(m => m.SEOModule)
      },
      {
        path: 'cust-query',
        loadChildren: () => import('./views/cust-query/cust-query.module').then(m => m.custSupportOrderIssueModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('./views/orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'wallet',
        loadChildren: () => import('./views/wallet/wallet.module').then(m => m.WalletModule)
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
