import { Component } from '@angular/core';
import { navItems } from '../../_nav';
import * as CryptoJS from 'crypto-js';
import { RouterModule, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DefaultLayoutService } from './default-layout.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  toastConfig: any = {
    timeOut: 3000,
    progressBar: true,
    progressAnimation: 'decreasing',
    positionClass: 'toast-bottom-right'
  }
  constructor(private router: Router, private _DefaultLayoutService: DefaultLayoutService, private toastr: ToastrService) { }
  userName: string = '';
  public sidebarMinimized = false;
  public navItems = navItems;

  ngOnInit() {
    this.checkLocalStorage();
  }

  checkLocalStorage() {  
    let item = localStorage.getItem('userDetails');
    if (item) {
      var bytes = CryptoJS.AES.decrypt(item.toString(), environment.cryptoKey);
      var decryptedUserDetailsData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      this.userName = decryptedUserDetailsData.first_name;
      this.verifyAuth();
    } else {
      this.notAllowed();
    }

  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  verifyAuth() {
    this._DefaultLayoutService.authVerify()
      .subscribe(
        data => {
      //  console.log(data);       
        },
        error=>{
          if(error.status==401){
            this.logOut();
          }          
        }
      )
  }

  logOut() {
    this.toastr.error('Logged Out', '', this.toastConfig)
    this.router.navigate(['/login']);
    localStorage.clear();
  }

  notAllowed() {
    this.toastr.warning('Please contact with administartor', 'Restricted', this.toastConfig)
    this.router.navigate(['/login']);
    localStorage.clear();
  }


}
