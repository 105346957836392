import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },
  {
    name: 'QR Manager',
    url: '/qr-manager',
    icon: 'fa fa-qrcode',
    children: [
      {
        name: 'Batch Master',
        url: '/qr-manager/batch-master',
        icon: 'fa fa-tasks'
      },
      {
        name: 'QR Master',
        url: '/qr-manager/qr-master',
        icon: 'fa fa-qrcode'
      }
    ]
  },
  {
    name: 'Orders',
    url: '/orders',
    icon: 'fa fa-square',
    children: [
      {
        name: 'Cancelled Orders',
        url: '/orders/order-cancel',
        icon: 'fa fa-window-close '
      },
    ]
  },
  {
    name: 'Wallet',
    url: '/wallet',
    icon: 'icon-wallet',
    children: [
       {
        name: 'Wallet Clubs ',
        url: '/wallet/wallet-club',
        icon: 'fa fa-cc-diners-club '
      },
      {
        name: 'Customer Wallet',
        url: '/wallet/wallet',
        icon: 'fa fa-money '
      },
      {
        name: 'Wallet History',
        url: '/wallet/wallet-history',
        icon: 'fa fa-exchange '
      },
    ]
  },
  {
    name: 'Masters',
    url: '/masters',
    icon: 'icon-list',
    children: [
      {
        name: 'Users',
        url: '/masters/users',
        icon: 'icon-people'
      }, {
        name: 'Customers',
        url: '/masters/customers',
        icon: 'fa fa-users'
      }, {
        name: 'Products',
        url: '/masters/products',
        icon: 'icon-layers'
      }, {
        name: 'Third Party',
        url: '/masters/tpm',
        icon: 'fa fa-industry'
      }, {
        name: 'Product USP',
        url: '/masters/prod-usp',
        icon: 'fa fa-check'
      }, {
        name: 'Discount Master',
        url: '/masters/discount-master',
        icon: 'fa fa-percent'
      },
    ]
  },
  {
    name: 'Product Suggestion Masters',
    url: '/product-suggestion-master',
    icon: 'icon-list',
    children: [
      {
        name: 'Product FAQs',
        url: '/product-suggestion-master/product-faq',
        icon: 'fa fa-question-circle'
      },
      {
        name: 'Product Reviews',
        url: '/product-suggestion-master/product-reviews',
        icon: 'fa fa-users'
      },
      {
        name: 'Product Videos',
        url: '/product-suggestion-master/product-videos',
        icon: 'fa fa-video-camera'
      },
      {
        name: 'Product Suggestion',
        url: '/product-suggestion-master/product-suggestion',
        icon: 'fa fa-product-hunt'
      },
    ]
  },
  {
    name: 'SEO',
    url: '/seo',
    icon: 'fa fa-google',
    children: [
      {
        name: 'Meta Keywords',
        url: '/seo/meta-keyword',
        icon: 'icon-key'
      },
    ]
  },
  {
    name: 'Customer Queries',
    url: '/cust-query',
    icon: 'fa fa-support',
    children: [
      {
        name: 'Website Order Issue',
        url: '/cust-query/cust-support-order-issue',
        icon: 'fa fa-question-circle'
      },
      {
        name: 'QR Scan Call Requests',
        url: '/cust-query/call-request',
        icon: 'fa fa-phone'
      },
      {
        name: 'Customer Feedback',
        url: '/product-suggestion-master/customer-feedback',
        icon: 'fa fa-product-hunt'
      }
    ]
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: 'icon-pie-chart',
    children: [
      {
        name: 'QR Scan',
        url: '/reports/scan-reports',
        icon: 'fa fa-qrcode'
      },
      {
        name: 'Suspicious Scan',
        url: '/reports/suspicious-scan',
        icon: 'fa fa-exclamation-circle'
      },
      {
        name: 'Product Frequently Bought Together',
        url: '/reports/frequently-bought-together-count',
        icon: 'fa fa-product-hunt'
      }
    ]
  },
  {
    divider: true
  },
];
